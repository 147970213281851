// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-basic-page-jsx": () => import("./../../../src/templates/BasicPage.jsx" /* webpackChunkName: "component---src-templates-basic-page-jsx" */),
  "component---src-templates-empty-page-jsx": () => import("./../../../src/templates/EmptyPage.jsx" /* webpackChunkName: "component---src-templates-empty-page-jsx" */),
  "component---src-templates-modules-page-jsx": () => import("./../../../src/templates/ModulesPage.jsx" /* webpackChunkName: "component---src-templates-modules-page-jsx" */)
}

